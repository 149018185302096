import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TopSection from "../components/topsection"
import Title from "../components/title"

const DspPage = ({ data, location, lang }) => (
  <Layout location={location} lang="sv">
    <SEO title="Dataskyddspolicy" keywords={['Dataskyddspolicy', 'GDPR']} />
    <TopSection img={data.img.childImageSharp.fluid} title="SH-Måleri AB" styleClass="proposal-background" lang="sv" />
    <div className="container py-5">
        <Title title="Dataskyddspolicy" />
        <p className="">
            Denna policy förklarar SH-Måleris (FO-nummer 2103924-6) behandling av personuppgifter i enlighet med EU:s dataskyddsförordning 2016/679 (GDPR). SH-Måleri är ansvarig för behandlingen av personuppgifter inom verksamheten.
        </p>
        <h5>Vilka personuppgifter behandlas</h5>
        <p className="">
            Vi behandlar personuppgifter som direkt eller indirekt förmedlas genom kontakt, kund- eller leverantörsförhållande eller webbplatsbesök. Personuppgifter som behandlas av oss är person- och kontaktinformation såsom namn, adress, e-post och telefonnummer. IP-adress, enhetsinformation, plattform, språkinställning, geografisk och demografisk positionering kan också behandlas. Uppgifter om hur webbplatsen används samlas in genom cookies, se vår cookiepolicy för mera information.
        </p>
        <h5>Vad används personuppgifterna till</h5>
        <p className="">
            Vi behandlar personuppgifter för att kunna uppfylla kund- och leverantörsavtal, sköta kontakt till exempel vid förfrågningar, samt följa lagstiftning. Uppgifter kan även behandlas i marknadsförings-, försäljnings- och statistiksyfte, bland annat för granskning och utveckling av verksamheten.
            Personuppgifter som vi behandlar följer något eller några av ovanstående syften. Den rättsliga grunden baseras på rättslig förpliktelse, samtycke och/eller avtal med den registrerade. Endast personuppgifter som behövs för ett syfte behandlas.
        </p>
        <h5>Vem delas personuppgifterna med</h5>
        <p className="">
            SH-Måleri kan dela personuppgifter med utvalda tredje parter under kontrollerade former så att uppgifterna hanteras säkert. Dessa tredje parter kan vara leverantörer som delges uppgifter som ett led i SH-Måleris verksamhet. Uppgifter varken säljs eller delas utan grund.
        </p>
        <h5>Var behandlas personuppgifterna</h5>
        <p className="">
            Vi behandlar alla personuppgifter inom EU/EES.
        </p>
        <h5>Hur länge sparas personuppgifterna</h5>
        <p className="">
            Vi sparar data så länge som det är nödvändigt för att uppfylla det syfte för vilket datan samlades in, eller för att utföra våra åtaganden alternativt så länge det krävs enligt lagstadgade lagringstider, t.ex. vad gäller bokföringslagen.
        </p>
        <h5>Hur skyddas personuppgifterna</h5>
        <p className="">
            Vi skyddar personuppgifter genom lämpliga tekniska och praktiska åtgärder. Skyddsnivån motsvaras av uppgifternas känslighet och behandlingens risk. 
        </p>
        <h5>Rättigheter</h5>
        <p className="">
            I enlighet med EU:s dataskyddsförordning har du rätt att få information om och tillgång till personuppgifter som vi behandlar om dig och möjlighet att rätta till dessa uppgifter om de är felaktiga. Du har även rätt att begära att bli raderad ur våra system om uppgifterna inte längre behövs i det syfte de blev insamlade. I vissa fall kan dock lagstiftning kräva att uppgifter finns kvar, då kan uppgifterna begränsas så att de endast är tillgängliga för dessa ändamål. Om uppgifter behandlas med ditt samtycke som grund, har du rätt att ta tillbaka samtycket. Du har även rätt att anmäla felaktig behandling av personuppgifter till Dataombudsmannens byrå. Se kontakt nedan för ärenden gällande vår behandling av dina personuppgifter.
        </p>
        <h5>Kontakta oss</h5>
        <p className="">
           Har du frågor kring dataskydd eller cookies är du välkommen att kontakta oss på: sven.hallmark@shmaleri.fi
        </p>
    </div>
  </Layout>
)

export const query = graphql`
  {
    img: file(relativePath: {eq: "background.jpg"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }`

export default DspPage